import { useContext } from "react";
import { IonButton, IonInput, IonIcon } from "@ionic/react";
import { AppContext } from "../state";
import { useApi } from "../config/api";

import iconMinus from "../assets/icons/icon-minus.svg";
import iconPlus from "../assets/icons/icon-plus.svg";
import iconTrash from "../assets/icons/icon-trash.svg";
import "./OrderPart.scss";

interface ContainerProps {
  index: number;
}

const Footer: React.FC<ContainerProps> = ({ index }) => {
  const { updateOrder } = useApi();
  const { state } = useContext(AppContext);

  function addItem() {
    const entries = [...state.order.entries];
    entries[index].quantity++;
    updateOrder( entries );
  }

  function removeItem() {
    const entries = [...state.order.entries];
    entries[index].quantity--;
    if (entries[index].quantity > 0)
      updateOrder( entries );
    else deleteItem();
  }

  function deleteItem() {
    const entries = [...state.order.entries];
    entries.splice(index, 1);
    updateOrder( entries );
  }

  return (
    <div className="cbo-orderpart">
      <div className="orderpart-header">
        <div className="header-car">
          <span className="car-name">
            {state.order.entries[index].brand}{" "}
            {state.order.entries[index].model}
          </span>
          {state.order.entries[index].year && (
            <span className="car-year">
              &nbsp;|&nbsp;{state.order.entries[index].year}
            </span>
          )}
        </div>
        <div className="header-part">
          {state.order.entries[index].name && (
            <div className="part-name">{state.order.entries[index].name}</div>
          )}
          {state.order.entries[index].description && (
            <div className="part-description">
              {state.order.entries[index].description}
            </div>
          )}
        </div>
      </div>
      <div className="orderpart-actions">
        <div className="actions-quantity">
          <IonButton type="button" onClick={() => removeItem()}>
            <IonIcon src={iconMinus} slot="icon-only" />
          </IonButton>
          <IonInput type="number" value={state.order.entries[index].quantity} />
          <IonButton type="button" onClick={() => addItem()}>
            <IonIcon src={iconPlus} slot="icon-only" />
          </IonButton>
        </div>
        <div className="actions-delete">
          <IonButton type="button" onClick={() => deleteItem()} fill="clear">
            <IonIcon src={iconTrash} slot="icon-only" />
          </IonButton>
        </div>
      </div>
    </div>
  );
};

export default Footer;
