import { useContext } from "react";
import { IonModal, IonContent, IonButton } from "@ionic/react";
import { AppContext } from "../state";
import { useApi } from "../config/api";
import routes from "../config/routes";

import "./Logout.scss";

interface ContainerProps {}

const Logout: React.FC<ContainerProps> = () => {
  const { state, dispatch } = useContext(AppContext);
  const { logout } = useApi();

  function _cancel() {
    dispatch({ type: "setModalLogout", data: false });
  }

  function _logout() {
    logout();
  }

  return (
    <IonModal
      className="cbo-logout"
      showBackdrop={true}
      isOpen={state.utils.modalLogout}
      onDidDismiss={() => _cancel()}
    >
      <IonContent>
        <div className="logout-inner cbo-container container--white cbo-cms">
          <h3>Déconnexion</h3>
          <p>Êtes-vous sur de vouloir vous déconnecter de votre compte ?</p>
          <div className="logout-actions">
            <IonButton className="cbo-button" onClick={() => _cancel()}>
              Annuler
            </IonButton>
            <IonButton
              routerLink={routes.home}
              className="cbo-link link--small"
              onClick={() => _logout()}
            >
              Me déconnecter
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default Logout;
