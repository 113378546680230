import React, { useContext } from "react";
import { IonContent, IonPage, useIonRouter } from "@ionic/react";
import { AppContext } from "../state";
import { useApi } from "../config/api";
import routes from "../config/routes";

import Header from "../components/Header";
import Footer from "../components/Footer";
import OrderPart from "../components/OrderPart";
import InfoBubble from "../components/InfoBubble";

import "./Order.scss";

const Order: React.FC = () => {
  const router = useIonRouter();
  const { sendOrder, loadOrder } = useApi();
  const { state, dispatch } = useContext(AppContext);

  function _sendOrder(){
    sendOrder()
      .then(() => {
        loadOrder();
        router.push(routes.ordersent);
      })
      .catch(() => {
        dispatch({ type:'setToaster', data:"Impossible d'envoyer le mémo." });
      });
  }

  function _search(){
    router.push(routes.search);
  }

  return (
    <IonPage>
      <Header white />
      <IonContent fullscreen>
        <div className="cbo-order cbo-container">

          <h1 className="order-title">Mémo commande</h1>

          {state.order.entries?.map(( item:any, index:number ) => (
            <OrderPart
              index={index}
              key={`orderpart_${index}`}
            />
          ))}

          {state.order.entries?.length === 0 && (
            <div>

              {state.order.lastSent && (
                <InfoBubble icon="info">
                  <p>
                    Dernière commande passée&nbsp;
                    <strong>
                      le&nbsp;
                      {new Date(state.order.lastSent.date).toLocaleDateString('fr-FR', {
                        weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
                      })}
                      &nbsp;à&nbsp;
                      {new Date(state.order.lastSent.date).toLocaleTimeString('fr-FR',{
                        hour: '2-digit', minute: '2-digit'
                      })}.
                    </strong>&nbsp;
                    Plus de détails dans le mail de confirmation envoyé.
                  </p>
                </InfoBubble>
              )}

              <div className="order-noresult">
                Vous n’avez aucun produit ajouté
              </div>
            </div>
          )}

        </div>
      </IonContent>
      {(state.order && state.order.entries?.length > 0) ? (
        <Footer
          buttonLabel="Commander"
          buttonAction={() => _sendOrder()}
        />
      ):(
        <Footer
          buttonLabel="Rechercher une fiche technique"
          buttonAction={() => _search()}
        />
      )}
    </IonPage>
  );
};

export default Order;
