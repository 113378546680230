import React, { useState, useContext } from "react";
import {
  IonContent,
  IonPage,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonModal,
  IonIcon
} from "@ionic/react";
import { useForm, Controller } from "react-hook-form";
import FormEmailPwd from "../components/FormEmailPwd";
import { useApi } from "../config/api";
import { AppContext } from "../state";

import iconCross from "../assets/icons/icon-cross.svg";
import "./Login.scss";

interface LoginProps {
  password?: boolean;
}

const Login: React.FC<LoginProps> = ({ password }) => {
  const { login } = useApi();
  const { dispatch } = useContext(AppContext);
  const [modalOpen, setModalOpen] = useState<any>(password ? true : false);

  interface FormData {
    email: string;
    password: string;
  }

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>();

  const onSubmit = (data: any) => {
    login(data.email, data.password)
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.code === 401) {
            dispatch({
              type: "setToaster",
              data: "Les identifiants saisis sont invalides."
            });
          }
          else{
            dispatch({
              type: "setToaster",
              data: "Impossible de vous connecter.<br />Veuillez contactez l'administrateur."
            });
          }
        }

        else{
          dispatch({
            type: "setToaster",
            data: "Une erreur inconnue est survenue.<br />Veuillez contactez l'administrateur."
          });
        }
      });
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="cbo-login cbo-container">
          <h1>
            <strong>Connexion</strong>
          </h1>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="cbo-form fullwidth"
            autoComplete="off"
          >
            <IonList lines="none">
              <IonItem>
                <IonLabel position="stacked">Email</IonLabel>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: true,
                    pattern: /.+@.+/
                  }}
                  render={({ field: { value, onChange } }) => (
                    <IonInput
                      type="email"
                      className={errors.email ? "cbo-errorfield" : ""}
                      value={value}
                      onIonChange={onChange}
                    />
                  )}
                />
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">Mot de passe</IonLabel>
                <Controller
                  name="password"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <IonInput
                      type="password"
                      className={errors.password ? "cbo-errorfield" : ""}
                      value={value}
                      onIonChange={onChange}
                    />
                  )}
                />
              </IonItem>

              <IonItem>
                <IonButton
                  className="cbo-link link--small"
                  onClick={() => setModalOpen(true)}
                >
                  <strong>Mot de passe oublié ?</strong>
                </IonButton>
              </IonItem>
            </IonList>

            <div className="ion-padding-top">
              <IonButton
                className="cbo-button"
                type="submit"
                expand="block"
                disabled={!!errors.email || !!errors.password}
              >
                Se connecter
              </IonButton>
            </div>
          </form>
        </div>

        <IonModal
          className="cbo-forgotpassword"
          showBackdrop={false}
          isOpen={modalOpen}
        >
          <IonContent>
            <div className="forgotpassword-inner cbo-container">
              <FormEmailPwd firstConnect={password} />

              <IonButton
                fill="clear"
                className="forgotpassword-close"
                onClick={() => setModalOpen(false)}
              >
                <IonIcon slot="icon-only" src={iconCross}></IonIcon>
              </IonButton>
            </div>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default Login;
