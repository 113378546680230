import { useState, useContext } from "react";
import { IonLabel, IonInput, IonButton } from "@ionic/react";
import { useForm, Controller } from "react-hook-form";
import { useApi } from "../config/api";
import { AppContext } from "../state";

import InfoBubble from "./InfoBubble";
import "./FormEmailPwd.scss";

interface ContainerProps {
  name?: string;
  description?: string;
  order?: boolean;
  infos?: string;
  firstConnect ?: boolean;
}

const FormEmailPwd: React.FC<ContainerProps> = ({
  name,
  description,
  order,
  infos,
  firstConnect
}) => {
  const { dispatch } = useContext(AppContext);
  const { askPassword } = useApi();
  const [sent, setSent] = useState<boolean>(false);
  const [mail, setMail] = useState<boolean>(false);

  interface FormData {
    email: string;
  }

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>();

  const onSubmit = (data: any) => {
    setSent(true);
    askPassword(data.email)
      .then(() => {
        setSent(false)
        setMail(true);
      })
      .catch((error) => {
        setSent(false);
        if (error.response && error.response.data) {
          if (error.response.data.code === 500) {
            dispatch({
              type: "setToaster",
              data: "Vous avez déjà une demande de mot de passe en cours. Veuillez vérifier vos messages."
            });
          } else if (error.response.data.code === 404) {
            dispatch({
              type: "setToaster",
              data: "Adresse email inconnue."
            });
          } else if (error.response.data.code === 401) {
            dispatch({
              type: "setToaster",
              data: "Votre compte n'est pas actif, veuillez contacter l'administrateur de l'application."
            });
          } else {
            dispatch({
              type: "setToaster",
              data: "Une erreur s'est produite."
            });
          }
        }
      });
  };

  return (
    <div className="cbo-formemailpwd">
      <div className="cbo-cms">
        {firstConnect ? (
          <h2>Générer un mot de passe</h2>
        ) : (
          <h2>Mot de passe oublié</h2>
        )}

        {!mail ? (
          <p>
            Entrez l’email avec lequel vous vous êtes inscrit et nous vous
            enverrons votre identifiant.
          </p>
        ) : (
          <InfoBubble icon="check">
            <p>
              Un lien de réinitialisation vient de vous être envoyé à &nbsp;
              <strong>l'adresse email indiquée</strong>, n'oubliez pas de
              vérifiez vos spams
            </p>
          </InfoBubble>
        )}
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="cbo-form fullwidth"
        autoComplete="off"
      >
        <div className="ion-padding-bottom">
          <IonLabel position="stacked">Email</IonLabel>
          <Controller
            name="email"
            control={control}
            rules={{
              required: true,
              pattern: /.+@.+/
            }}
            render={({ field: { value, onChange } }) => (
              <IonInput
                type="email"
                className={errors.email ? "cbo-errorfield" : ""}
                value={value}
                onIonChange={onChange}
              />
            )}
          />
        </div>

        <div className="ion-padding-top">
          <IonButton
            className="cbo-button"
            type="submit"
            expand="block"
            disabled={!!errors.email || sent}
            fill="outline"
          >
            {firstConnect ? (
              <span>Demander un mot de passe</span>
            ) : (
              <span>Renvoyer le mot de passe</span>
            )}
          </IonButton>
        </div>
      </form>
    </div>
  );
};

export default FormEmailPwd;
