const order = {
  state: {
    entries: [],
    lastSent: null,
    loadingEntries: false
  },

  reducer: (state, action) => {
    switch (action.type) {
      case "startOrderEntries": {
        return { ...state, entries: [], loadingEntries: true };
      }
      case "endOrderEntries": {
        return { ...state, loadingEntries: false };
      }
      case "setOrderEntries": {
        return { ...state, entries: action.data, loadingEntries: false };
      }
      case "setOrderLastSent": {
        return { ...state, lastSent: action.data };
      }
      case "resetOrder": {
        return {
          ...state,
          entries: [],
          lastSent: null,
          loadingEntries: false
        };
      }
      default:
      // Do nothing
    }
    return state;
  }
};

export default order;
