import { IonFooter, IonToolbar, IonButton } from "@ionic/react";
import "./Footer.scss";

interface ContainerProps {
  white?: boolean;
  buttonLabel?: string;
  buttonTarget?: string;
  buttonAction?: () => void;
  buttonDisabled?: boolean;
  buttonDirection?: "none" | "forward" | "back" | "root" | undefined;
}

const Footer: React.FC<ContainerProps> = ({
  white,
  buttonLabel,
  buttonTarget,
  buttonAction,
  buttonDisabled,
  buttonDirection
}) => {
  function onClickAction() {
    if (buttonAction) buttonAction();
  }

  return (
    <IonFooter className={`cbo-footer ${white ? "footer--white" : ""}`}>
      <IonToolbar>
        <IonButton
          routerLink={buttonTarget}
          routerDirection={buttonDirection}
          onClick={() => onClickAction()}
          disabled={buttonDisabled}
          className="footer-button cbo-button"
          expand="block"
        >
          {buttonLabel ? buttonLabel : 'Veuillez saisir un label'}
        </IonButton>
      </IonToolbar>
    </IonFooter>
  );
};

export default Footer;
