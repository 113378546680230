import { IonButton, IonIcon, IonBadge } from "@ionic/react";

import iconCart from "../assets/icons/icon-cart.svg";
import iconLogout from "../assets/icons/icon-logout.svg";

import "./ActionButton.scss";

interface ContainerProps {
  type: string;
  badge?: number;
  target?: string;
  action?: () => void;
  active?: boolean
}

const Footer: React.FC<ContainerProps> = ({
  type,
  badge,
  target,
  action,
  active
}) => {
  function onClickAction() {
    if (action) action();
  }

  return (
    <IonButton
      className={`cbo-actionbtn ${active ? 'actionbtn--active':''}`}
      routerLink={target}
      onClick={() => onClickAction()}
    >
      {type === "cart" && (
        <IonIcon slot="icon-only" src={iconCart}></IonIcon>
      )}
      {type === "logout" && (
        <IonIcon slot="icon-only" src={iconLogout}></IonIcon>
      )}
      {badge && (
        <IonBadge>{badge}</IonBadge>
      )}
    </IonButton>
  );
};

export default Footer;
