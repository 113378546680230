import { useState, useEffect } from "react";
import { IonInput, IonButton } from "@ionic/react";

import "./Autocomplete.scss";

interface ContainerProps {
  items: any;
  itemlabel: string;
  selected?: any;
  onclickselected?: () => void;
  onselect(obj: any): void;
  placeholder?: string;
  hideinput?: boolean;
}

const Autocomplete: React.FC<ContainerProps> = ({
  items,
  itemlabel,
  selected,
  onclickselected,
  onselect,
  placeholder,
  hideinput
}) => {
  const [value, setValue] = useState<any>(null);
  const [selection, setSelection] = useState<any[]>([]);

  const onchange = (str: string) => {
    const newSelection: any[] = [];
    items.forEach((item: any) => {
      const testStr = str.toLowerCase();
      const testName = item[itemlabel].toLowerCase();

      if (testName.includes(testStr)) newSelection.push(item);
    });

    setValue(null);
    setSelection(newSelection);
  };

  const onclick = () => {
    if (onclickselected) onclickselected();
  };

  useEffect(() => {
    //if (hideinput) {
      setSelection(items);
    //}
  }, [items]);

  useEffect(() => {
    setValue(selected);
  }, [selected]);

  return (
    <div className="cbo-autocomplete">
      {!hideinput && (
        <div className="autocomplete-field">
          <IonInput
            type="text"
            onIonChange={(e: any) => onchange(e.target.value)}
            placeholder={placeholder}
          />
        </div>
      )}

      {(!hideinput && value && value[itemlabel]) ? (
        <div className="autocomplete-suggestions">
          <div className="suggestions-el">
            <IonButton
              type="button"
              className="cbo-button"
              expand="block"
              color="secondary"
              onClick={(e) => onclick()}
            >
              {value[itemlabel]}
            </IonButton>
          </div>
        </div>
      ) : (
        <div className="autocomplete-suggestions">
          {selection.map((item: any, index: number) => {
            return (
              <div
                className="suggestions-el"
                key={`autocomplete_item_${index}`}
              >
                <IonButton
                  type="button"
                  className="cbo-button"
                  expand="block"
                  color={value && value === item ? "secondary" : "tertiary"}
                  onClick={(e) => {
                    hideinput && value && value === item
                      ? onclick()
                      : onselect(item);
                  }}
                >
                  {item[itemlabel] ? item[itemlabel] : 'Non renseigné'}
                </IonButton>
              </div>
            );
        })}
        </div>
      )}
    </div>
  );
};

export default Autocomplete;
