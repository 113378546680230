const models = {
  state: {
    entries: [],
    loadingEntries: false
  },

  reducer: (state, action) => {
    switch (action.type) {
      case "startModelsEntries": {
        return { ...state, entries: [], loadingEntries: true };
      }
      case "endModelsEntries": {
        return { ...state, loadingEntries: false };
      }
      case "setModelsEntries": {
        return { ...state, entries: action.data, loadingEntries: false };
      }
      case "resetModels": {
        return {
          ...state,
          entries: [],
          loadingEntries: false
        };
      }
      default:
      // Do nothing
    }
    return state;
  }
};

export default models;
