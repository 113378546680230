const invoice = {
  state: {
    customer: {},
    service: {},
    mode: 'preview'
  },

  reducer: (state, action) => {
    switch (action.type) {
      case "setInvoiceCustomer": {
        return { ...state, customer: action.data };
      }
      case "setInvoiceService": {
        return { ...state, service: action.data };
      }
      case "setInvoicePrice": {
        return { ...state, price: action.data };
      }
      case "setInvoiceMode": {
        return { ...state, mode: action.data };
      }
      case "resetInvoice": {
        return {
          ...state,
          customer: {},
          service: {},
          mode: 'preview'
        };
      }
      default:
      // Do nothing
    }
    return state;
  }
};

export default invoice;
