const brands = {
  state: {
    entries: [],
    loadingEntries: false
  },

  reducer: (state, action) => {
    switch (action.type) {
      case "startBrandsEntries": {
        return { ...state, entries: [], loadingEntries: true };
      }
      case "endBrandsEntries": {
        return { ...state, loadingEntries: false };
      }
      case "setBrandsEntries": {
        return { ...state, entries: action.data, loadingEntries: false };
      }
      case "resetBrands": {
        return {
          ...state,
          entries: [],
          loadingEntries: false
        };
      }
      default:
      // Do nothing
    }
    return state;
  }
};

export default brands;
