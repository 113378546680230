import {
  IonContent,
  IonPage,
  IonImg,
  IonButton
} from "@ionic/react";
import routes from "../config/routes";

import logo from "../assets/images/logo.png";
import "./Home.scss";

const Home: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="cbo-home cbo-container">
          <div className="home-logo">
            <IonImg src={logo} />
          </div>
          <div className="home-button">
            <IonButton
              type="button"
              routerLink={routes.login}
              routerDirection="forward"
              color="tertiary"
              expand="block"
              className="cbo-button"
            >
              S'identifier
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Home;
