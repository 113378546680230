const user = {
  state: {
    email: null,
    token: null,
    welcome: null
  },

  reducer: (state, action) => {
    switch (action.type) {
      case "setUserEmail": {
        return { ...state, email: action.data };
      }
      case "setUserToken": {
        return { ...state, token: action.data };
      }
      case "setUserWelcome": {
        return { ...state, welcome: action.data };
      }
      case "setUserUUID": {
        return { ...state, uuid: action.data };
      }
      case "resetUser": {
        return {
          ...state,
          email: null,
          token: null,
          welcome: null
        };
      }
      default:
      // Do nothing
    }
    return state;
  }
};

export default user;
