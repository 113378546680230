import { IonFooter, IonToolbar, IonButton, IonIcon } from "@ionic/react";
import routes from "../config/routes";

import iconBack from "../assets/icons/icon-back.svg";
import "./SearchFooter.scss";

interface ContainerProps {
  step: number;
  max: number;
  action?: () => void;
  button?: boolean;
}

const SearchFooter: React.FC<ContainerProps> = ({
  step,
  max,
  action,
  button
}) => {
  function onClickAction() {
    if (action) action();
  }

  function dots() {
    const dotsList = [];
    for (let i = 1; i <= max; i++) {
      dotsList.push(
        <div
          className={`dots-el ${step === i ? "el--active" : ""}`}
          key={`search_dot_${i}`}
        ></div>
      );
    }

    return dotsList;
  }

  return (
    <IonFooter className="cbo-searchfooter">
      <IonToolbar>
        {button && (
          <div className="searchfooter-top">
            <IonButton
              className="cbo-button"
              routerLink={routes.sheet}
              expand="block"
            >
              Voir la fiche
            </IonButton>
          </div>
        )}

        <div className="searchfooter-bottom">
          <div className="bottom-dots">{dots()}</div>

          {action && step > 1 && (
            <IonButton
              className="bottom-back cbo-link"
              onClick={() => onClickAction()}
              slot="start"
            >
              <IonIcon src={iconBack} />
              Retour
            </IonButton>
          )}
        </div>
      </IonToolbar>
    </IonFooter>
  );
};

export default SearchFooter;
