import React, { useEffect, useContext, useCallback } from "react";
import { IonLabel, IonTextarea, IonInput } from "@ionic/react";
import { useForm, Controller } from "react-hook-form";
import { AppContext } from "../state";

interface ComponentProps {
  submit?: boolean;
}

const FormInvoiceService: React.FC<ComponentProps> = ({ submit }) => {
  const { state, dispatch } = useContext(AppContext);

  interface ServiceProps {
    title?: string;
    description?: string;
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<ServiceProps>();

  const onSubmit = useCallback((data: any) => {
    dispatch({ type: "setInvoiceService", data: data });
    dispatch({ type: "setInvoiceMode", data: "preview" });
  }, [dispatch]);

  useEffect(() => {
    if (submit) handleSubmit(onSubmit)();
  }, [submit, handleSubmit, onSubmit]);

  useEffect(() => {
    Object.keys(state.invoice.service).forEach((key: any) => {
      const value = state.invoice.service[key];
      setValue(key, value);
    });
  }, [state.invoice.service, setValue]);

  return (
    <form className="edition-form cbo-form" autoComplete="off">
      <div className="ion-padding-bottom">
        <IonLabel position="stacked">Titre de la prestation</IonLabel>
        <div className="ion-padding-bottom">
          <Controller
            name="title"
            control={control}
            rules={{
              required: true
            }}
            render={({ field: { value, onChange } }) => (
              <IonInput
                className={errors.title ? "cbo-errorfield" : ""}
                value={value}
                onIonChange={onChange}
              />
            )}
          />
        </div>

        <IonLabel position="stacked">Décrivez la prestation</IonLabel>
        <Controller
          name="description"
          control={control}
          rules={{
            required: true
          }}
          render={({ field: { value, onChange } }) => (
            <IonTextarea
              className={errors.description ? "cbo-errorfield" : ""}
              value={value}
              onIonChange={onChange}
            />
          )}
        />
      </div>
    </form>
  );
};

export default FormInvoiceService;
