import React, { useState, useContext, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonRouterOutlet,
  setupIonicReact,
  IonLoading,
  IonToast,
  IonAlert
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { AppContext } from "./state";
import { useApi } from "./config/api";

import Home from "./pages/Home";
import Login from "./pages/Login";
import Password from "./pages/Password";
import Search from "./pages/Search";
import Sheet from "./pages/Sheet";
import Order from "./pages/Order";
import OrderSent from "./pages/OrderSent";
import Invoice from "./pages/Invoice";
import InvoiceSent from "./pages/InvoiceSent";

import Logout from "./components/Logout";

import routes from "./config/routes";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/app.scss";
import "./theme/utils.scss";
import "./theme/variables.css";

setupIonicReact();

const App: React.FC = () => {
  const { relogin, loadWelcome, loadBrands, loadOrder } = useApi();
  const { state, dispatch } = useContext(AppContext);
  const [auth, setAuth] = useState<boolean>(false);

  useEffect(() => {
    relogin();
  }, [relogin]);

  useEffect(() => {
    if (state.user.token) {
      setAuth(true);

      loadWelcome();
      loadBrands();
      loadOrder();
    }
  }, [state.user.token, setAuth, loadWelcome, loadBrands, loadOrder]);

  useEffect(() => {
    if (!state.user.token) {
      setAuth(false);
    }
  }, [state.user.token, setAuth]);

  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route
            exact
            path={routes.home}
            render={(props: any) => {
              return !auth ? (
                <Home {...props} />
              ) : (
                <Redirect to={routes.search} />
              );
            }}
          />
          <Route
            exact
            path="/"
             render={(props: any) => {
              return !auth ? (
                <Redirect to={routes.home} />
              ) : (
                <Redirect to={routes.search} />
              );
            }}
          />
          <Route
            exact
            path={routes.login}
            render={(props: any) => {
              return !auth ? (
                <Login {...props} />
              ) : (
                <Redirect to={routes.search} />
              );
            }}
          />
          <Route
            exact
            path={routes.loginPassword}
            render={(props: any) => {
              return !auth ? (
                <Login {...props} password />
              ) : (
                <Redirect to={routes.search} />
              );
            }}
          />
          <Route
            exact
            path={routes.password}
            render={(props: any) => {
              return !auth ? (
                <Password {...props} />
              ) : (
                <Redirect to={routes.search} />
              );
            }}
          />
          <Route
            exact
            path={routes.search}
            render={(props: any) => {
              return auth ? (
                <Search {...props} />
              ) : (
                <Redirect to={routes.login} />
              );
            }}
          />
          <Route
            exact
            path={routes.sheet}
            render={(props: any) => {
              return auth ? (
                <Sheet {...props} />
              ) : (
                <Redirect to={routes.login} />
              );
            }}
          />
          <Route
            exact
            path={routes.order}
            render={(props: any) => {
              return auth ? (
                <Order {...props} />
              ) : (
                <Redirect to={routes.login} />
              );
            }}
          />
          <Route
            exact
            path={routes.ordersent}
            render={(props: any) => {
              return auth ? (
                <OrderSent {...props} />
              ) : (
                <Redirect to={routes.login} />
              );
            }}
          />
          <Route
            exact
            path={routes.invoice}
            render={(props: any) => {
              return auth ? (
                <Invoice {...props} />
              ) : (
                <Redirect to={routes.login} />
              );
            }}
          />
          <Route
            exact
            path={routes.invoiceprefill}
            render={(props: any) => {
              return auth ? (
                <Invoice {...props} prefill />
              ) : (
                <Redirect to={routes.login} />
              );
            }}
          />
          <Route
            exact
            path={routes.invoicesent}
            render={(props: any) => {
              return auth ? (
                <InvoiceSent {...props} />
              ) : (
                <Redirect to={routes.login} />
              );
            }}
          />
        </IonRouterOutlet>
      </IonReactRouter>

      <Logout />

      <IonLoading
        cssClass="cbo-loader"
        isOpen={state.utils.loader}
        message={"Chargement en cours"}
        spinner="crescent"
      />

      <IonToast
        cssClass="cbo-toaster"
        isOpen={!!state.utils.toaster}
        message={state.utils.toaster}
        color="danger"
        duration={2000}
        onDidDismiss={() => dispatch({ type: "resetToaster" })}
      />

      <IonAlert
        isOpen={!!state.user.welcome}
        message={state.user.welcome}
        buttons={["Fermer"]}
      />

    </IonApp>
  );
};

export default App;
