const utils = {
  state: {
    modalLogout: false,
    loader: false,
    toaster: null,
  },

  reducer: (state, action) => {
    switch (action.type) {
      case "setModalLogout": {
        return { ...state, modalLogout: action.data };
      }
      case "hideLoader": {
        return { ...state, loader: false };
      }
      case "showLoader": {
        return { ...state, loader: true };
      }
      case "setToaster": {
        return { ...state, toaster: action.data };
      }
      case "resetToaster": {
        return { ...state, toaster: null };
      }
      case "resetUtils": {
        return {
          ...state,
          modalLogout: false,
          loader: false,
          toaster: null
        };
      }
      default:
      // Do nothing
    }
    return state;
  }
};

export default utils;
