import React, { useContext, useState, useEffect, useCallback, useRef } from "react";
import { IonContent, IonPage, IonButton, useIonRouter, IonAccordion, IonAccordionGroup, IonLabel, IonItem } from "@ionic/react";
import { AppContext } from "../state";
import { useApi } from "../config/api";
import routes from "../config/routes";

import InputMask from "react-input-mask";
import Header from "../components/Header";
import InvoiceButton from "../components/InvoiceButton";
import Autocomplete from "../components/Autocomplete";
import SearchFooter from "../components/SearchFooter";
import InfoBubble from "../components/InfoBubble";

import "./Search.scss";

  interface BrandOrModel {
    id: any;
    nom: string;
  }

  interface SerieOrYear {
    id: any;
    serieCommerciale: string;
    serieVin: string;
    annee: string;
  }

  interface LicensePlate {
    id: number;
    licensePlate: string;
  }
  
const Search: React.FC = () => {
  const router = useIonRouter();
  const { state, dispatch } = useContext(AppContext);
  const {
    loadModels,
    loadSeries,
    searchSerie,
    loadRegistration,
    loadSheet,
    getLicensePlate,
  } = useApi();

  const [searchMode, setSearchMode] = useState<string>("registration");
  const [searchStep, setSearchStep] = useState<number>(1);
  const licensePlatesRef = useRef<any>(null);

  const [registration, setRegistration] = useState<string>("");
  const [registrationError, setRegistrationError] = useState<any>(null);
  const [registrationMasked, setRegistrationMasked] = useState<string>("");
  const [registrationSearched, setRegistrationSearched] = useState<string>("");

  function regMaskBuilder() {
    const firstChar = registration.substr(0, 1);

    if (firstChar && firstChar.length > 0) {
      if (/^[0-9]+$/.test(firstChar)) return "999-aa-99";
      else if (/^[a-zA-Z]+$/.test(firstChar)) return "aa-999-aa";
    }

    return "";
  }

  function handleRegistrationChange(val: string) {

    if(val === '___-__-__' || val === '__-___-__')
      val = '';

    setRegistrationMasked(val);
    setRegistration(val.replace(/-|_/gi, ""));
  }

  function prevSearchStep() {
    setSearchStep(searchStep - 1);
  }

  const defaultBrandOrModel = {
    id: null,
    nom: ""
  };

  const defaultSerieOrYear = {
    id: null,
    serieCommerciale: "",
    serieVin: "",
    annee: ""
  };

  const [brand, setBrand] = useState<BrandOrModel>(defaultBrandOrModel);
  const [model, setModel] = useState<BrandOrModel>(defaultBrandOrModel);
  const [series, setSeries] = useState<SerieOrYear[]>();
  const [serie, setSerie] = useState<SerieOrYear>(defaultSerieOrYear);
  const [years, setYears] = useState<SerieOrYear[]>();
  const [year, setYear] = useState<SerieOrYear>(defaultSerieOrYear);
  const [licensePlates, setLicensePlates] = useState<LicensePlate[]>([]);

  /** Switch search mode function */

  function switchMode(mode: string) {
    if(mode !== searchMode){
      setBrand(defaultBrandOrModel);
      setModel(defaultBrandOrModel);
      setSeries([]);
      setSerie(defaultSerieOrYear);
      setYears([]);
      setYear(defaultSerieOrYear);

      setRegistration('');
      setRegistrationError(null);
      setRegistrationMasked('');
      setRegistrationSearched('');

      setSearchStep(1);
      setSearchMode(mode);
    }
  }

  /** Brand selected **/

  function selectBrand(obj: any) {
    setModel(defaultBrandOrModel);
    setSerie(defaultSerieOrYear);
    setYear(defaultSerieOrYear);
    setBrand(obj);
  }

  useEffect(() => {
    if (brand && brand.id) {
      loadModels(brand.id);
      setSearchStep(2);
    }
  }, [loadModels, brand]);

  /** Model selected **/

  function selectModel(obj: any) {
    setSerie(defaultSerieOrYear);
    setYear(defaultSerieOrYear);
    setModel(obj);
  }

  useEffect(() => {
    if (model && model.id) {
      loadSeries(model.id);
      setSearchStep(3);
    }
  }, [loadSeries, model]);

  /** Series loaded **/

  useEffect(() => {
    const seriesArr: SerieOrYear[] = [];
    state.series.entries.forEach((item: SerieOrYear) => {
      const seriesFound: SerieOrYear[] = seriesArr.filter(
        (obj: SerieOrYear) => {
          return obj.serieCommerciale === item.serieCommerciale;
        }
      );
      if (seriesFound.length === 0) seriesArr.push(item);
    });
    setSeries(seriesArr);
  }, [state.series.entries]);

  /** Serie selected **/

  function selectSerie(obj: any) {
    const yearsArr: SerieOrYear[] = [];
    state.series.entries.forEach((item: SerieOrYear) => {
      if (item.serieCommerciale === obj.serieCommerciale) {
        const yearsFound: SerieOrYear[] = yearsArr.filter(
          (obj: SerieOrYear) => {
            return obj.annee === item.annee;
          }
        );
        if (yearsFound.length === 0) yearsArr.push(item);
      }
    });

    setYear(defaultSerieOrYear);
    setSerie(obj);
    setYears(yearsArr);
    setSearchStep(4);
  }

  /** Search method **/

  const searchByModel = useCallback(() => {
    if (
      year &&
      year.id &&
      brand &&
      brand.id &&
      model &&
      model.id &&
      serie &&
      serie.id
    )
      loadSheet(year.id, brand, model, year);
  }, [year, brand, model, serie, loadSheet]);

  /** Year selected **/

  function selectYear(obj: any) {
    setYear(obj);
  }

  useEffect(() => {
    if (year && year.id) {
      searchByModel();
    }
  }, [year, searchByModel]);
  
  useEffect(() => {
    getLicensePlate().then(({ data }) => setLicensePlates(data)).catch((error) => {
      dispatch({
        type: "setToaster",
        data: "Une erreur s'est produite."
      });
    })},
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  /** Search by registration **/

  function searchByRegistration(initialValue ?: string) {
    setRegistrationSearched(initialValue || registrationMasked.toUpperCase());
    loadRegistration(initialValue || registrationMasked.toUpperCase())
      .then((reg: any) => {
        if (reg.data && reg.data.marque) {
          searchSerie(
            reg.data.marque,
            reg.data.modele,
            reg.data.annee
          )
          .then((response: any) => {
            if (response.data && response.data[0] ) {
              loadSheet(
                response.data[0].id,
                response.data[0].modele.marque,
                response.data[0].modele,
                response.data[0]
              )
              .then((response: any) => {
                dispatch({type:"setSheetRegistration", data:reg.data});
                router.push(routes.sheet);
              })
              .catch((error:any) => {
                if (error.response.data.code === 404) {
                  setRegistrationError( reg.data );
                } else {
                  dispatch({
                    type: "setToaster",
                    data: "Une erreur s'est produite."
                  });
                }
              })
            }
          })
          .catch((error:any) => {
            if (error.response.data.code === 404) {
              setRegistrationError( reg.data );
            } else {
              dispatch({
                type: "setToaster",
                data: "Une erreur s'est produite."
              });
            }
          })
        }
      })
      .catch((error:any) => {
        if (error.response && error.response.data) {
          if (error.response.data.code === 404) {
            dispatch({
              type: "setToaster",
              data: "Immatriculation inconnue, vous pouvez faire une recherche manuelle"
            });
          } else {
            dispatch({
              type: "setToaster",
              data: "Une erreur s'est produite."
            });
          }
        }
      });
  }
  
  return (
    <IonPage>
      <Header />
      <IonContent>
        <div className="cbo-search cbo-container">
          <h1 className="search-title">Rechercher une fiche par...</h1>

          <div className="search-tabs">
            <IonButton
              type="button"
              className="cbo-button"
              fill={searchMode === "registration" ? "solid" : "outline"}
              onClick={() => switchMode("registration")}>
              Immatriculation
            </IonButton>
            <IonButton
              type="button"
              className="cbo-button"
              fill={searchMode === "model" ? "solid" : "outline"}
              onClick={() => switchMode("model")}>
              Modèle
            </IonButton>
          </div>

          {searchMode === "registration" ? (
            <div className="search-registration">
              <div>
                <IonAccordionGroup ref={licensePlatesRef}>
                  <IonAccordion value="licensePlates">
                  <IonItem slot="header">
                    <IonLabel>
                        Afficher l'historique
                    </IonLabel>
                  </IonItem>
                    <div className="ion-padding" slot="content">
                      {licensePlates?.map((elem) => (
                        <div key={elem.id}>
                        <IonButton className="cbo-link" fill="clear" onClick={() => searchByRegistration(elem.licensePlate)}>
                          {elem?.licensePlate}
                        </IonButton>
                        </div>
                      ))}
                    </div>
                  </IonAccordion>
                </IonAccordionGroup>
              </div>
              <div className="cbo-form">
                <div className="ion-padding-top ion-padding-bottom">
                  <InputMask
                    mask={regMaskBuilder()}
                    value={registrationMasked}
                    onChange={(e) => handleRegistrationChange(e.target.value)}
                    className="cbo-input input--uppercase"
                  />
                </div>
                <div className="registration-submit ion-padding-top ion-padding-bottom">
                  <IonButton
                    type="button"
                    className="cbo-button"
                    disabled={registration.length !== 7}
                    onClick={() => searchByRegistration()}>
                    Voir la fiche
                  </IonButton>
                </div>

                {registrationError && (
                  <div className="search-fallback ion-padding-top">
                    <InfoBubble icon="info">
                      <p>
                        Nous n'avons pas trouvé de fiche technique correspondant
                        au numéro
                        <br />
                        <strong className="ion-text-uppercase">
                          {registrationSearched}
                        </strong>
                      </p>
                      <p className="ion-padding-top ion-padding-bottom">
                        Voici les informations dont nous disposons :
                      </p>
                      <ul>
                        {registrationError.marque && (
                          <li>
                            Marque : <strong>{registrationError.marque}</strong>
                          </li>
                        )}
                        {registrationError.modele && (
                          <li>
                            Modèle : <strong>{registrationError.modele}</strong>
                          </li>
                        )}
                        {registrationError.datePremImmat && (
                          <li>
                            1ère immatriculation :&nbsp;
                            <strong>
                              {new Date(
                                registrationError.datePremImmat
                              ).toLocaleDateString()}
                            </strong>
                          </li>
                        )}
                        {registrationError.vin && (
                          <li>
                            N° de châssis :{" "}
                            <strong>{registrationError.vin}</strong>
                          </li>
                        )}
                      </ul>
                    </InfoBubble>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="search-model">
              <div className="model-steps">
                {searchStep === 1 && (
                  <div className="steps-el cbo-form">
                    <h3>Sélectionner une marque</h3>
                    <div className="ion-padding-bottom">
                      <Autocomplete
                        items={state.brands.entries}
                        itemlabel="nom"
                        onclickselected={() => setSearchStep(2)}
                        onselect={(obj: any) => selectBrand(obj)}
                        placeholder="ex : Volkswagen"
                        selected={brand}
                      />
                    </div>
                  </div>
                )}

                {searchStep === 2 && (
                  <div className="steps-el cbo-form">
                    <h3>Sélectionner un modèle</h3>
                    <div className="ion-padding-bottom">
                      <Autocomplete
                        items={state.models.entries}
                        itemlabel="nom"
                        onclickselected={() => setSearchStep(3)}
                        onselect={(obj: any) => selectModel(obj)}
                        placeholder="ex : Golf"
                        selected={model}
                      />
                    </div>
                  </div>
                )}

                {searchStep === 3 && (
                  <div className="steps-el cbo-form">
                    <h3>Sélectionner une série</h3>
                    <div className="ion-padding-bottom">
                      <Autocomplete
                        items={series}
                        itemlabel="serieCommerciale"
                        onclickselected={() => setSearchStep(4)}
                        onselect={(obj: any) => selectSerie(obj)}
                        selected={serie}
                        hideinput
                      />
                    </div>
                  </div>
                )}

                {searchStep === 4 && (
                  <div className="steps-el cbo-form">
                    <h3>Sélectionner une année</h3>
                    <div className="ion-padding-bottom">
                      <Autocomplete
                        items={years}
                        itemlabel="annee"
                        onclickselected={() => searchByModel()}
                        onselect={(obj: any) => selectYear(obj)}
                        selected={year}
                        hideinput
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </IonContent>

      {searchMode === "registration" && <InvoiceButton />}
      {searchMode === "model" && (
        <SearchFooter
          step={searchStep}
          max={4}
          action={() => prevSearchStep()}
          button={year.id && searchStep === 4}
        />
      )}
    </IonPage>
  );
};

export default Search;
