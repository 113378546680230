import React, { useContext } from "react";
import { IonContent, IonPage } from "@ionic/react";
import { AppContext } from "../state";
import routes from "../config/routes";

import Header from "../components/Header";
import Footer from "../components/Footer";
import InfoBubble from "../components/InfoBubble";

import "./InvoiceSent.scss";

const OrderSent: React.FC = () => {
  const { state } = useContext(AppContext);

  return (
    <IonPage>
      <Header white />
      <IonContent fullscreen>
        <div className="cbo-invoicesent cbo-container">

          <h1 className="invoicesent-title">Mémo envoyé</h1>
          <InfoBubble icon="check">
            <p>
              Vous allez recevoir un mail de confirmation à<br />
              <strong>{state.user.email}</strong>
            </p>
          </InfoBubble>

        </div>
      </IonContent>
      <Footer
        buttonLabel="Retour à l'accueil"
        buttonTarget={routes.search}
        buttonDirection="back"
      />
    </IonPage>
  );
};

export default OrderSent;
