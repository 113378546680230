import React, { useState, useEffect, useContext } from "react";
import {
  IonContent,
  IonPage,
  IonButton,
  IonIcon,
  IonInput,
  useIonRouter
} from "@ionic/react";
import { AppContext } from "../state";
import { useApi } from "../config/api";
import routes from "../config/routes";

import Header from "../components/Header";
import Footer from "../components/Footer";
import FormInvoiceCustomer from "../components/FormInvoiceCustomer";
import FormInvoiceService from "../components/FormInvoiceService";

import iconBack from "../assets/icons/icon-back.svg";
import iconMore from "../assets/icons/icon-more.svg";
import "./Invoice.scss";

interface InvoiceProps {
  prefill?: boolean;
}

const Invoice: React.FC<InvoiceProps> = ({ prefill }) => {
  const router = useIonRouter();
  const { sendInvoice } = useApi();
  const { state, dispatch } = useContext(AppContext);

  /* Customer */

  const [submitCustomer, setSubmitCustomer] = useState<boolean>(false);
  function submitFormCustomer() {
    setSubmitCustomer(true);
  }

  useEffect(() => {
    if (submitCustomer) setSubmitCustomer(false);
  }, [submitCustomer]);

  /* Service */

  const [submitService, setSubmitService] = useState<boolean>(false);
  function submitFormService() {
    setSubmitService(true);
  }

  useEffect(() => {
    if (submitService) setSubmitService(false);
  }, [submitService]);

  /* Price */

  const [priceFrom, setPriceFrom] = useState<string>("excl");
  const [priceExcl, setPriceExcl] = useState<any>(null);
  const [priceIncl, setPriceIncl] = useState<any>(null);
  const [priceVat, setPriceVat] = useState<any>(null);

  function twoDecimals(price: number) {
    return parseFloat((Math.round(price * 100) / 100).toFixed(2));
  }

  function setPriceFromExcl(excl: number) {
    if (priceFrom === "excl") {
      if (isNaN(excl)) excl = 0;

      const incl = excl * 1.2;
      const vat = incl - excl;

      setPriceExcl(twoDecimals(excl));
      setPriceIncl(twoDecimals(incl));
      setPriceVat(twoDecimals(vat));
    }
  }

  function setPriceFromIncl(incl: number) {
    if (priceFrom === "incl") {
      if (isNaN(incl)) incl = 0;

      const excl = incl / 1.2;
      const vat = incl - excl;

      setPriceExcl(twoDecimals(excl));
      setPriceIncl(twoDecimals(incl));
      setPriceVat(twoDecimals(vat));
    }
  }

  /* Invoice sending */

  function _sendInvoice() {
    sendInvoice({
      email: state.invoice.customer.email,
      titre: state.invoice.service.title,
      description: state.invoice.service.description,
      priceExcl: priceExcl,
      tva: priceVat,
      lastname: state.invoice.customer.lastname,
      firstname: state.invoice.customer.firstname,
      address: state.invoice.customer.address,
      zipcode: state.invoice.customer.zipcode,
      city: state.invoice.customer.city,
      statZipcode: state.invoice.customer.statZipcode,
      statType: state.invoice.customer.statType
    })
    .then(() => {
      setPriceFrom("excl");
      setPriceExcl(null);
      setPriceIncl(null);
      setPriceVat(null);

      router.push(routes.invoicesent);
    })
    .catch(() => {
      dispatch({
        type: "setToaster",
        data: "Impossible d'envoyer la facture."
      });
    });
  }

  /* Prefill */

  function capFirstLetter(string: string) {
    if(string)
      return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    if (prefill) {
      dispatch({
        type: "setInvoiceService",
        data: {
          title:
            state.sheet.mode === "duplicate"
              ? "Reproduction double des clés avec programmation"
              : "Remplacement des clés avec programmation",
          description:
            capFirstLetter(state.sheet.brand.nom) +
            " " +
            capFirstLetter(state.sheet.model.nom) +
            " | " +
            state.sheet.serie.annee +
            (state.sheet.registration.numeroPlaque ? "\n" + state.sheet.registration.numeroPlaque : '')
        }
      });
    }
    else{
      dispatch({ type:"resetInvoice" });
    }
  }, [
    dispatch,
    state.sheet.mode,
    state.sheet.brand.nom,
    state.sheet.model.nom,
    state.sheet.serie.annee,
    state.sheet.registration.numeroPlaque,
    prefill
  ]);

  return (
    <IonPage>
      <Header white />
      <IonContent>
        <div className="cbo-invoice cbo-container">
          {/* Invoice header block */}

          <div className="invoice-header">
            <h1 className="header-title">Facture</h1>
            <div className="header-date cbo-subtitle">
              {new Date().toLocaleDateString()}
            </div>
          </div>

          {/* Invoice content block */}

          {state.invoice.mode === "preview" && (
            <div className="invoice-content">
              <div className="content-area">
                <div className="area-title cbo-sectiontitle">Facturation</div>

                {Object.keys(state.invoice.customer).length === 0 ? (
                  <div className="area-trigger">
                    <IonButton
                      color="tertiary"
                      expand="block"
                      onClick={() =>
                        dispatch({ type: "setInvoiceMode", data: "customer" })
                      }
                    >
                      <span className="trigger-inner">
                        <span>Ajouter un client</span>
                        <IonIcon src={iconMore}></IonIcon>
                      </span>
                    </IonButton>
                  </div>
                ) : (
                  <div
                    className="area-summary cbo-cms"
                    onClick={() =>
                      dispatch({ type: "setInvoiceMode", data: "customer" })
                    }
                  >
                    {(state.invoice.customer.firstname ||
                      state.invoice.customer.lastname) && (
                      <p>
                        <strong>
                          {state.invoice.customer.firstname}{" "}
                          {state.invoice.customer.lastname}
                        </strong>
                      </p>
                    )}

                    {state.invoice.customer.email && (
                      <p>{state.invoice.customer.email}</p>
                    )}
                    {state.invoice.customer.vehicle && (
                      <p>{state.invoice.customer.vehicle}</p>
                    )}

                    {(state.invoice.customer.address ||
                      state.invoice.customer.zipcode ||
                      state.invoice.customer.city) && (
                      <p>
                        {state.invoice.customer.address}{" "}
                        {state.invoice.customer.zipcode}{" "}
                        {state.invoice.customer.city}
                      </p>
                    )}
                  </div>
                )}
              </div>
              <div className="content-area">
                <div className="area-title cbo-sectiontitle">Prestation</div>

                {Object.keys(state.invoice.service).length === 0 ? (
                  <div className="area-trigger">
                    <IonButton
                      color="tertiary"
                      expand="block"
                      onClick={() =>
                        dispatch({ type: "setInvoiceMode", data: "service" })
                      }
                    >
                      <span className="trigger-inner">
                        <span>Ajouter une prestation</span>
                        <IonIcon src={iconMore}></IonIcon>
                      </span>
                    </IonButton>
                  </div>
                ) : (
                  <div
                    className="area-summary cbo-cms"
                    onClick={() =>
                      dispatch({ type: "setInvoiceMode", data: "service" })
                    }
                  >
                    <p>
                      <strong>{state.invoice.service.title}</strong>
                    </p>

                    <p>
                    {state.invoice.service.description.split("\n").map((item:string, index:number) => (
                        <span key={'desc_'+index}>
                          {item}
                          <br/>
                        </span>
                    ))}
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Invoice total block */}

          {state.invoice.mode === "preview" && (
            <div className="invoice-total">
              <div className="total-line">
                <span className="line-label">Total HT</span>
                <span className="line-value">
                  <IonInput
                    type="number"
                    placeholder="0"
                    value={priceExcl}
                    onIonChange={(e: any) => setPriceFromExcl(e.target.value)}
                    onIonFocus={() => setPriceFrom("excl")}
                  />
                  &nbsp;&euro;
                </span>
              </div>
              <div className="total-line">
                <span className="line-label">TVA 20%</span>
                <span className="line-value">{priceVat || 0}&nbsp;&euro;</span>
              </div>
              <div className="total-spacer"></div>
              <div className="total-line">
                <span className="line-label">Total TTC</span>
                <span className="line-value">
                  <IonInput
                    type="number"
                    placeholder="0"
                    value={priceIncl}
                    onIonChange={(e: any) => setPriceFromIncl(e.target.value)}
                    onIonFocus={() => setPriceFrom("incl")}
                  />
                  &nbsp;&euro;
                </span>
              </div>
            </div>
          )}

          {/* Customer details edition */}

          {state.invoice.mode === "customer" && (
            <div className="invoice-edition">
              <IonButton
                type="button"
                className="cbo-sectionlink"
                onClick={() =>
                  dispatch({ type: "setInvoiceMode", data: "preview" })
                }
              >
                <IonIcon src={iconBack}></IonIcon>
                Facturation
              </IonButton>

              <FormInvoiceCustomer submit={submitCustomer} />
            </div>
          )}

          {/* Service details edition */}

          {state.invoice.mode === "service" && (
            <div className="invoice-edition">
              <IonButton
                type="button"
                className="cbo-sectionlink"
                onClick={() =>
                  dispatch({ type: "setInvoiceMode", data: "preview" })
                }
              >
                <IonIcon src={iconBack}></IonIcon>
                Prestation
              </IonButton>

              <FormInvoiceService submit={submitService} />
            </div>
          )}
        </div>
      </IonContent>

      {state.invoice.mode === "preview" && (
        <Footer
          buttonLabel="Envoyer la facture"
          buttonAction={() => _sendInvoice()}
          buttonDisabled={
            !state.invoice.customer.email ||
            state.invoice.service === "" ||
            !priceExcl ||
            priceExcl === 0
          }
        />
      )}

      {state.invoice.mode === "customer" && (
        <Footer
          buttonLabel="Valider"
          buttonAction={() => submitFormCustomer()}
        />
      )}

      {state.invoice.mode === "service" && (
        <Footer
          buttonLabel="Valider"
          buttonAction={() => submitFormService()}
        />
      )}
    </IonPage>
  );
};

export default Invoice;
