import { useCallback, useContext } from "react";
import { DeviceUUID } from "device-uuid";
import { AppContext } from "../state";
import axios from "axios";
import endpoints from "../config/endpoints";

/* Create Axios Instance */

const axiosConf = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    Accept: "application/json"
  }
});

export function useApi() {
  const { dispatch } = useContext(AppContext);

  /* Update Axios Interceptors */

  axiosConf.interceptors.response.use(
    function(response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function(error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error

      if (error.response && error.response.status === 401) {
        if(localStorage.getItem("metacle_token")){
          console.log('logout');
          logout();
        }
      }

      if (!error.response) {
      }

      return Promise.reject(error);
    }
  );

  /* Login / Password */

  const relogin = useCallback(() => {
    const token = localStorage.getItem("metacle_token");
    const email = localStorage.getItem("metacle_email");
    if (token && email) {
      axiosConf.defaults.headers.common["Authorization"] = "Bearer " + token;
      dispatch({ type: "setUserToken", data: token });
      dispatch({ type: "setUserEmail", data: email });
    }
  }, [dispatch]);

  const login = useCallback(
    async (username, password) => {
      dispatch({ type: "showLoader" });

      const du = new DeviceUUID().parse();

      const dua = [
        du.language,
        du.platform,
        du.resolution,
        du.os,
        du.browser,
        du.cpuCores,
        du.pixelDepth,
        du.colorDepth,
        du.isAuthoritative,
        du.silkAccelerated,
        du.isTouchScreen,
        du.isiPhone,
        du.isiPad,
        du.isSamsung,
        du.isDesktop,
        du.isMobile,
        du.isTablet,
        du.isWebkit
      ];

      const uuid = du.hashMD5(dua.join(':'));

      return await new Promise((resolve, reject) => {
        axiosConf
          .post(endpoints.login, {
            username: username,
            password: password,
            phone_fuid: uuid
          })
          .then((response) => {
            axiosConf.defaults.headers.common["Authorization"] =
              "Bearer " + response.data.token;
            localStorage.setItem("metacle_token", response.data.token);
            localStorage.setItem("metacle_email", username);
            dispatch({ type: "setUserToken", data: response.data.token });
            dispatch({ type: "setUserEmail", data: username });
            dispatch({ type: "hideLoader" });
            resolve(response);
          })
          .catch((error) => {
            dispatch({ type: "hideLoader" });
            reject(error);
          });
      });
    },
    [dispatch]
  );

  const logout = useCallback(() => {
    axiosConf.defaults.headers.common["Authorization"] = null;
    localStorage.clear();
    dispatch({ type: "resetUser" });
    dispatch({ type: "resetBrands" });
    dispatch({ type: "resetModels" });
    dispatch({ type: "resetSeries" });
    dispatch({ type: "resetSheet" });
    dispatch({ type: "resetInvoice" });
    dispatch({ type: "resetOrder" });
    dispatch({ type: "resetRegistration" });
    dispatch({ type: "resetUtils" });
  }, [dispatch]);

  const askPassword = useCallback(
    async (email) => {
      dispatch({ type: "showLoader" });

      return await new Promise((resolve, reject) => {
        axiosConf
          .post(endpoints.password, { email: email })
          .then((response) => {
            dispatch({ type: "hideLoader" });
            resolve(response);
          })
          .catch((error) => {
            dispatch({ type: "hideLoader" });
            reject(error);
          });
      });
    },
    [dispatch]
  );

  const setPassword = useCallback(
    async (token, password) => {
      dispatch({ type: "showLoader" });

      return await new Promise((resolve, reject) => {
        axiosConf
          .post(endpoints.passwordReset, { token: token, password: password })
          .then((response) => {
            dispatch({ type: "hideLoader" });
            resolve(response);
          })
          .catch((error) => {
            dispatch({ type: "hideLoader" });
            reject(error);
          });
      });
    },
    [dispatch]
  );

  /* Brands */

  const loadBrands = useCallback(
    (str) => {
      dispatch({ type: "showLoader" });
      dispatch({ type: "resetBrands" });

      axiosConf
        .get(endpoints.brands)
        .then((response) => {
          dispatch({ type: "setBrandsEntries", data: response.data });
          dispatch({ type: "hideLoader" });
        })
        .catch(() => {
          dispatch({ type: "hideLoader" });
        });
    },
    [dispatch]
  );

  /* Models */

  const loadModels = useCallback(
    (id) => {
      dispatch({ type: "showLoader" });
      dispatch({ type: "resetModels" });

      axiosConf
        .get(endpoints.models, { params: { marque_id: id } })
        .then((response) => {
          dispatch({ type: "setModelsEntries", data: response.data });
          dispatch({ type: "hideLoader" });
        })
        .catch(() => {
          dispatch({ type: "hideLoader" });
        });
    },
    [dispatch]
  );

  /* Series */

  const loadSeries = useCallback(
    (id) => {
      dispatch({ type: "showLoader" });
      dispatch({ type: "resetSeries" });

      axiosConf
        .get(endpoints.series, { params: { modele_id: id } })
        .then((response) => {
          dispatch({ type: "setSeriesEntries", data: response.data });
          dispatch({ type: "hideLoader" });
        })
        .catch(() => {
          dispatch({ type: "hideLoader" });
        });
    },
    [dispatch]
  );

  const searchSerie = useCallback(
    async (brand, model, year) => {
      dispatch({ type: "showLoader" });
      dispatch({ type: "resetSeries" });

      return await new Promise((resolve, reject) => {
        axiosConf
          .get(endpoints.seriesSearch, {
            params: { marque: brand, modele: model, annee: year }
          })
          .then((response) => {
            dispatch({ type: "setSerieSearch", data: response.data[0] });
            dispatch({ type: "hideLoader" });
            resolve(response);
          })
          .catch((error) => {
            dispatch({ type: "hideLoader" });
            reject(error);
          });
      });
    },
    [dispatch]
  );

  /* Sheet */

  const loadSheet = useCallback(
    async (id, brand, model, serie) => {
      dispatch({ type: "showLoader" });
      dispatch({ type: "resetSheet" });

      return await new Promise((resolve, reject) => {
        axiosConf
          .get(endpoints.sheet, { params: { serie_id: id } })
          .then((response) => {
            dispatch({ type: "setSheetEntry", data: response.data[0] });
            dispatch({ type: "setSheetBrand", data: brand });
            dispatch({ type: "setSheetModel", data: model });
            dispatch({ type: "setSheetSerie", data: serie });
            dispatch({ type: "setSheetMode", data: "duplicate" });
            dispatch({ type: "hideLoader" });
            resolve(response);
          })
          .catch((error) => {
            dispatch({ type: "hideLoader" });
            reject(error);
          });
      });
    },
    [dispatch]
  );

  /* Registration */

  const loadRegistration = useCallback(
    async (number) => {
      dispatch({ type: "showLoader" });
      dispatch({ type: "resetRegistration" });

      return await new Promise((resolve, reject) => {
        axiosConf
          .get(endpoints.registration, { params: { numero_plaque: number } })
          .then((response) => {
            dispatch({ type: "setRegistration", data: response.data });
            dispatch({ type: "hideLoader" });
            resolve(response);
          })
          .catch((error) => {
            dispatch({ type: "hideLoader" });
            reject(error);
          });
      });
    },
    [dispatch]
  );

  /* Invoice */

  const sendInvoice = useCallback(
    async (data) => {
      dispatch({ type: "showLoader" });

      return await new Promise((resolve, reject) => {
        axiosConf
          .post(endpoints.invoice, data)
          .then((response) => {
            dispatch({ type: "resetInvoice" });
            dispatch({ type: "hideLoader" });
            resolve(response);
          })
          .catch((error) => {
            dispatch({ type: "hideLoader" });
            reject(error);
          });
      });
    },
    [dispatch]
  );

  /* Order */

  const loadOrder = useCallback(() => {
    dispatch({ type: "showLoader" });
    dispatch({ type: "resetOrder" });

    axiosConf
      .get(endpoints.order)
      .then((response) => {
        dispatch({ type: "setOrderEntries", data: response.data.memo });
        dispatch({ type: "setOrderLastSent", data: response.data.lastSent });
        dispatch({ type: "hideLoader" });
      })
      .catch(() => {
        dispatch({ type: "hideLoader" });
      });
  }, [dispatch]);

  const updateOrder = useCallback(
    (entries) => {
      dispatch({ type: "showLoader" });

      axiosConf
        .post(endpoints.orderUpdate, { content: JSON.stringify(entries) })
        .then((response) => {
          dispatch({ type: "setOrderEntries", data: entries });
          dispatch({ type: "hideLoader" });
        })
        .catch(() => {
          dispatch({ type: "hideLoader" });
        });
    },
    [dispatch]
  );

  const sendOrder = useCallback(
    async () => {
      dispatch({ type: "showLoader" });

      return await new Promise((resolve, reject) => {
        axiosConf
          .post(endpoints.orderSend)
          .then((response) => {
            dispatch({ type: "resetOrder" });
            dispatch({ type: "hideLoader" });
            resolve(response);
          })
          .catch((error) => {
            dispatch({ type: "hideLoader" });
            reject(error);
          });
      });
    },
    [dispatch]
  );

  /* Welcome */

  const loadWelcome = useCallback(
    async () => {
      dispatch({ type: "showLoader" });

      return await new Promise((resolve, reject) => {
        axiosConf
          .get(endpoints.welcome)
          .then((response) => {
            dispatch({ type: "setUserWelcome", data: response.data.value });
            dispatch({ type: "hideLoader" });
            resolve(response);
          })
          .catch((error) => {
            dispatch({ type: "hideLoader" });
            reject(error);
          });
      });
    },
    [dispatch]
  );

  const getLicensePlate = () => axiosConf.get(endpoints.licensePlates);

  return {
    relogin,
    login,
    logout,
    askPassword,
    setPassword,
    loadBrands,
    loadModels,
    loadSeries,
    searchSerie,
    loadSheet,
    loadRegistration,
    sendInvoice,
    loadOrder,
    updateOrder,
    sendOrder,
    loadWelcome,
    getLicensePlate,
  };
}
