const routes = {
  home: "/home",
  login: "/login",
  loginPassword: "/login/password",
  password: "/password/:token",
  search: "/search",
  sheet: "/sheet",
  invoice: "/invoice",
  invoiceprefill: "/invoice/prefill",
  invoicesent: "/invoice-sent",
  order: "/order",
  ordersent: "/order-sent",

  /*
  evaluation: "/activities/evaluation/:id",
  evaluations: "/activities/evaluation",
  */

  get(route, params) {
    let r = this[route];
    if (params)
      for (let [key, value] of Object.entries(params)) {
        r = r.replace(":" + key, value);
      }
    return r;
  }
};

export default routes;
