const sheet = {
  state: {
    mode: 'duplicate',
    registration: {},
    brand: {},
    model: {},
    serie: {},
    entry: {},
    loadingEntry: false
  },

  reducer: (state, action) => {
    switch (action.type) {
      case "startSheetEntry": {
        return { ...state, entry: {}, loadingEntry: true };
      }
      case "endSheetEntry": {
        return { ...state, loadingEntry: false };
      }
      case "setSheetEntry": {
        return { ...state, entry: action.data, loadingEntry: false };
      }
      case "setSheetRegistration": {
        return { ...state, registration: action.data };
      }
      case "setSheetBrand": {
        return { ...state, brand: action.data };
      }
      case "setSheetModel": {
        return { ...state, model: action.data };
      }
      case "setSheetSerie": {
        return { ...state, serie: action.data };
      }
      case "setSheetMode": {
        return { ...state, mode: action.data };
      }
      case "resetSheet": {
        return {
          ...state,
          mode: 'duplicate',
          registration: {},
          brand: {},
          model: {},
          serie: {},
          entry: {},
          loadingEntry: false
        };
      }
      default:
      // Do nothing
    }
    return state;
  }
};

export default sheet;
