import React, { useContext } from "react";
import { IonContent, IonPage, IonButton } from "@ionic/react";
import { AppContext } from "../state";
import routes from "../config/routes";
import groupBy from 'lodash/groupBy';

import Header from "../components/Header";
import Footer from "../components/Footer";
import Difficulty from "../components/Difficulty";
import Part from "../components/Part";
import Picture from "../components/Picture";

import "./Sheet.scss";

type VideoDuplicate = 'videoDuplicateKey';
type VideoLost = 'videoLostKey';


type VideoEntry = [`${VideoDuplicate | VideoLost}${'Entitled' | 'Url'}${'1' | '2' | '3'}`, string];

const Sheet: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const groupVideoInformations = (entries: any[]): any => {
    return groupBy(entries, (key: [VideoEntry[0], string]) => key[0].replace(/^\D+/g, ''))
  }

  if (state.sheet.entry != null && state.sheet.entry.id != null) {
    const duplicateVideos = groupVideoInformations(Object.entries(state.sheet.entry).filter(([key,]) => key.startsWith('videoDuplicateKey')));
    const lostVideos = groupVideoInformations(Object.entries(state.sheet.entry).filter(([key,]) => key.startsWith('videoLostKey')));
    return (
      <IonPage>
        <Header white backHref={routes.search} />
        <IonContent>
          <div className="cbo-sheet cbo-container container--white">

            {/* Sheet header block */}
            <div className="sheet-header">
              {state.sheet.registration.energie && (
                  <div className="header-energy">
                    {state.sheet.registration.energie}
                  </div>
              )}

              <h1 className="header-title">
                {state.sheet.brand.nom} | {state.sheet.model.nom}
              </h1>
              <div className="header-serie cbo-subtitle">
                {state.sheet.serie.annee}
                {state.sheet.serie.serieCommerciale && (
                    <span>&nbsp;|&nbsp;{state.sheet.serie.serieCommerciale}</span>
                )}
              </div>
              <div className="header-infos">
                {state.sheet.registration.vin && (
                    <p>
                      <strong>VIN</strong>
                      {state.sheet.registration.vin}
                    </p>
                )}

                {state.sheet.serie.serieVin && (
                    <p>
                      <strong>SERIE</strong>
                      {state.sheet.serie.serieVin}
                    </p>
                )}
              </div>
              <div className="header-tabs">
                <IonButton
                    type="button"
                    className="cbo-button"
                    fill={state.sheet.mode === "duplicate" ? "solid" : "outline"}
                    onClick={() =>
                        dispatch({type: "setSheetMode", data: "duplicate"})
                    }>
                  Double
                </IonButton>
                <IonButton
                    type="button"
                    className="cbo-button"
                    fill={state.sheet.mode === "lost" ? "solid" : "outline"}
                    onClick={() =>
                        dispatch({type: "setSheetMode", data: "lost"})
                    }>
                  Perte
                </IonButton>
              </div>

              {state.sheet.entry.addnd &&
                  state.sheet.entry.addnd !== "" &&
                  state.sheet.mode === "duplicate" && (
                      <div className="header-difficulty">
                        <Difficulty level={state.sheet.entry.addnd}/>
                      </div>
                  )}

              {state.sheet.entry.aklnd &&
                  state.sheet.entry.aklnd !== "" &&
                  state.sheet.mode === "lost" && (
                      <div className="header-difficulty">
                        <Difficulty level={state.sheet.entry.aklnd}/>
                      </div>
                  )}
            </div>

            <div className="sheet-parts">
              {state.sheet.mode === 'duplicate' ? (
                  <>
                    {state.sheet.entry.pvcAddBlade && (
                        <Part
                            name="PVC add blade"
                            description={state.sheet.entry.pvcAddBlade}
                            order={false}
                        />
                    )}
                    {state.sheet.entry.pvcAddRemote && (
                        <Part
                            name="PVC add Remote"
                            description={state.sheet.entry.pvcAddRemote}
                            order={false}
                        />
                    )}
                    {state.sheet.entry.pvcAddKeyless && (
                        <Part
                            name="PVC add Keyless"
                            description={state.sheet.entry.pvcAddKeyless}
                            order={false}
                        />
                    )}
                  </>
              ) : (
                  <>
                    {state.sheet.entry.pvcAklBlade && (
                        <Part
                            name="PVC akl blade"
                            description={state.sheet.entry.pvcAklBlade}
                            order={false}
                        />
                    )}
                    {state.sheet.entry.pvcAklRemote && (
                        <Part
                            name="PVC akl Remote"
                            description={state.sheet.entry.pvcAklRemote}
                            order={false}
                        />
                    )}
                    {state.sheet.entry.pvcAklKeyless && (
                        <Part
                            name="PVC akl Keyless"
                            description={state.sheet.entry.pvcAklKeyless}
                            order={false}
                        />
                    )}
                  </>
              )}
            </div>
            {/* Sheet introduction block */}
              <div className="sheet-introduction cbo-cms">
                {state.sheet.entry.details && <p>{state.sheet.entry.details}</p>}

                {state.sheet.mode === "lost" &&
                    Object.values(lostVideos).map(
                        (elem: any) =>
                            elem[0][1] != null && elem[0][1] !== '' && (
                                <figure key={elem[0][0]}>
                                  <iframe width="420" height="315" title={elem[0][1]}
                                          src={elem[0][1].replace('https://youtu.be/', 'https://www.youtube.com/embed/').replace('/watch?v=', '/embed/')}>
                                  </iframe>
                                  <figcaption>
                                    {elem[1][1]}
                                  </figcaption>
                                </figure>
                            )
                    )}
                {state.sheet.mode === "duplicate" &&
                    Object.values(duplicateVideos).map(
                        (elem: any) =>
                            elem[1][1] != null && elem[1][1] !== '' && (
                                <figure key={elem[0][0]}>
                                  <iframe width="420" height="315" title={elem[0][1]}
                                          src={elem[0][1].replace('https://youtu.be/', 'https://www.youtube.com/embed/').replace('/watch?v=', '/embed/')}>
                                  </iframe>
                                  <figcaption>
                                    {elem[1][1]}
                                  </figcaption>
                                </figure>
                            )
                    )}
              </div>

              {/* Sheet parts block */}

              <div className="sheet-parts">
                {state.sheet.mode === "lost" && state.sheet.entry.lishi && (
                    <Part name="Lishi" description={state.sheet.entry.lishi}/>
                )}
                {state.sheet.entry.insertName && (
                    <Part
                        name="Insert"
                        description={state.sheet.entry.insertName}
                        order
                    />
                )}

                {state.sheet.entry.fournisseur1 && (
                    <Part
                        name="Fournisseur 1"
                        description={state.sheet.entry.fournisseur1}
                        order
                    />
                )}
                {state.sheet.entry.fournisseur2 && (
                    <Part
                        name="Fournisseur 2"
                        description={state.sheet.entry.fournisseur2}
                        order
                    />
                )}

                {state.sheet.entry.blade && (
                    <Part
                        name="Blade"
                        description={state.sheet.entry.blade}
                        order
                    />
                )}
                {state.sheet.entry.keyless && (
                    <Part
                        name="Keyless"
                        description={state.sheet.entry.keyless}
                        order
                    />
                )}
                {state.sheet.entry.type && (
                    <Part name="Type" description={state.sheet.entry.type}/>
                )}
                {state.sheet.mode === "duplicate" &&
                    state.sheet.entry.typeAdd && (
                        <Part
                            name="Type Add"
                            description={state.sheet.entry.typeAdd}
                        />
                    )}
                {state.sheet.mode === "duplicate" &&
                    state.sheet.entry.chipCopie1 && (
                        <Part
                            name="CHIP copie 1"
                            description={state.sheet.entry.chipCopie1}
                            order
                        />
                    )}
                {state.sheet.mode === "duplicate" &&
                    state.sheet.entry.chipCopie2 && (
                        <Part
                            name="CHIP copie 2"
                            description={state.sheet.entry.chipCopie2}
                            order
                        />
                    )}
                {state.sheet.mode === "duplicate" &&
                    state.sheet.entry.pinAddKey && (
                        <Part
                            name="PIN add key"
                            description={state.sheet.entry.pinAddKey}
                        />
                    )}
                {state.sheet.mode === "duplicate" &&
                    state.sheet.entry.progadd1 && (
                        <Part
                            name="Prog ADD 1"
                            description={state.sheet.entry.progadd1}
                        />
                    )}
                {state.sheet.mode === "duplicate" &&
                    state.sheet.entry.progadd2 && (
                        <Part
                            name="Prog ADD 2"
                            description={state.sheet.entry.progadd2}
                            infos={state.sheet.entry.infoAdd}
                        />
                    )}
                {state.sheet.mode === "duplicate" && state.sheet.entry.pvcAdd && (
                    <Part name="PVC add" description={state.sheet.entry.pvcAdd}/>
                )}
                {state.sheet.mode === "lost" && state.sheet.entry.typeAkl && (
                    <Part name="Type AKL" description={state.sheet.entry.typeAkl}/>
                )}
                {state.sheet.mode === "lost" && state.sheet.entry.pinLostKey && (
                    <Part
                        name="PIN lost key"
                        description={state.sheet.entry.pinLostKey}
                    />
                )}
                {state.sheet.mode === "lost" && state.sheet.entry.codeCleLc1 && (
                    <Part
                        name="Code clé LC1"
                        description={state.sheet.entry.codeCleLc1}
                    />
                )}
                {state.sheet.mode === "lost" && state.sheet.entry.progakl1 && (
                    <Part
                        name="Prog AKL 1"
                        description={state.sheet.entry.progakl1}
                    />
                )}
                {state.sheet.mode === "lost" && state.sheet.entry.progakl2 && (
                    <Part
                        name="Prog AKL 2"
                        description={state.sheet.entry.progakl2}
                        infos={state.sheet.entry.infoAkl}
                    />
                )}
                {state.sheet.mode === "lost" && state.sheet.entry.lishiT3 && (
                    <Part
                        name="Lishi T3"
                        description={state.sheet.entry.lishiT3}
                        order
                    />
                )}
                {state.sheet.mode === "lost" && state.sheet.entry.pvcAkl && (
                    <Part name="PVC AKL" description={state.sheet.entry.pvcAkl}/>
                )}
                {state.sheet.entry.equipements && (
                    <Part
                        name="Equipement"
                        description={state.sheet.entry.equipements}
                    />
                )}
                {state.sheet.entry.remoteXhorse && (
                    <Part
                        name="Remote XHORSE"
                        description={state.sheet.entry.remoteXhorse}
                        order
                    />
                )}
                {state.sheet.entry.refXhorse && (
                    <Part
                        name="Ref XHORSE"
                        description={state.sheet.entry.refXhorse}
                    />
                )}
                {state.sheet.entry.remoteKd && (
                    <Part
                        name="Remote KD"
                        description={state.sheet.entry.remoteKd}
                        order
                    />
                )}
                {state.sheet.entry.refKd && (
                    <Part name="Ref KD" description={state.sheet.entry.refKd}/>
                )}
                {state.sheet.entry.remote && (
                    <Part name="Remote" description={state.sheet.entry.remote}/>
                )}
                {state.sheet.entry.oem && (
                    <Part name="OEM" description={state.sheet.entry.oem}/>
                )}
                {state.sheet.entry.frequence && (
                    <Part
                        name="Fréquence"
                        description={state.sheet.entry.frequence}
                    />
                )}
                {state.sheet.entry.pinRequis && (
                    <Part
                        name="PIN requis"
                        description={state.sheet.entry.pinRequis}
                    />
                )}
                {state.sheet.entry.codePinLc1 && (
                    <Part
                        name="Code PIN LC1"
                        description={state.sheet.entry.codePinLc1}
                    />
                )}
                {state.sheet.entry.codePinLc2 && (
                    <Part
                        name="Code PIN LC2"
                        description={state.sheet.entry.codePinLc2}
                    />
                )}
                {state.sheet.entry.precode && (
                    <Part
                        name="Precode"
                        description={state.sheet.entry.precode ? "Oui" : "Non"}
                    />
                )}
                {state.sheet.entry.pile && (
                    <Part name="Pile" description={state.sheet.entry.pile} order/>
                )}
                {state.sheet.entry.progTelecommandes && (
                    <Part
                        name="Prog télécommandes"
                        description={state.sheet.entry.progTelecommandes}
                    />
                )}
                {state.sheet.entry.procSyncTelecommandes && (
                    <Part
                        name="Procédure synchronisation des télécommandes"
                        description={state.sheet.entry.procSyncTelecommandes}
                    />
                )}
                {state.sheet.entry.icCard && (
                    <Part name="ID card" description={state.sheet.entry.icCard}/>
                )}
                {state.sheet.entry.silicaCard && (
                    <Part
                        name="Silica card"
                        description={state.sheet.entry.silicaCard}
                    />
                )}
                {state.sheet.entry.inertJma && (
                    <Part
                        name="Insert JMA"
                        description={state.sheet.entry.inertJma}
                        order
                    />
                )}
                {state.sheet.entry.info && (
                    <Part name="Info" description={state.sheet.entry.info}/>
                )}
              </div>

              {/* Sheet pictures block */}
              <div className="sheet-pictures">
                {state.sheet.entry.image1File &&
                    state.sheet.entry.image1File !== "" && (
                        <Picture
                            src={state.sheet.entry.image1File}
                            legend={state.sheet.entry.image1Description}
                        />
                    )}
                {state.sheet.entry.image2File &&
                    state.sheet.entry.image2File !== "" && (
                        <Picture
                            src={state.sheet.entry.image2File}
                            legend={state.sheet.entry.image2Description}
                        />
                    )}
                {state.sheet.entry.image3File &&
                    state.sheet.entry.image3File !== "" && (
                        <Picture
                            src={state.sheet.entry.image3File}
                            legend={state.sheet.entry.image3Description}
                        />
                    )}
                {state.sheet.entry.image4File &&
                    state.sheet.entry.image4File !== "" && (
                        <Picture
                            src={state.sheet.entry.image4File}
                            legend={state.sheet.entry.image4Description}
                        />
                    )}
                {state.sheet.entry.image5File &&
                    state.sheet.entry.image5File !== "" && (
                        <Picture
                            src={state.sheet.entry.image5File}
                            legend={state.sheet.entry.image5Description}
                        />
                    )}
                {state.sheet.entry.image6File &&
                    state.sheet.entry.image6File !== "" && (
                        <Picture
                            src={state.sheet.entry.image6File}
                            legend={state.sheet.entry.image6Description}
                        />
                    )}
              </div>
            </div>
        </IonContent>
        <Footer
          buttonLabel="Générer une facture"
          buttonTarget={routes.invoiceprefill}
          white
        />
      </IonPage>
    );
  } else {
    return (
      <IonPage>
        <Header white />
        <IonContent fullscreen>
          <div className="cbo-notfound cbo-container">
            <div className="notfound-text">
              Vous devez d'abord faire une recherche pour afficher une fiche
              technique.
            </div>
          </div>
        </IonContent>
        <Footer
          buttonLabel="Faire une recherche"
          buttonTarget={routes.search}
          white
        />
      </IonPage>
    );
  }
};

export default Sheet;
