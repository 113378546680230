import { useContext, useEffect, useState } from "react";
import { IonHeader, IonToolbar, IonBackButton, IonButton, IonIcon } from "@ionic/react";
import { AppContext } from "../state";
import { useLocation } from "react-router-dom";
import routes from "../config/routes";

import ActionButton from "./ActionButton";
import iconBack from "../assets/icons/icon-back.svg";
import "./Header.scss";

interface ContainerProps {
  backHref?: string;
  white?: boolean;
}

const Header: React.FC<ContainerProps> = ({ backHref, white }) => {
  const location = useLocation();
  const { state, dispatch } = useContext(AppContext);
  const [orderItems, setOrderItems] = useState<number>(0);

  useEffect(() => {
    let numberItems = 0;
    state.order.entries?.forEach((part: any) => {
      numberItems += part.quantity;
    });
    setOrderItems(numberItems);
  }, [state.order.entries]);

  function openLogoutModal() {
    dispatch({ type: "setModalLogout", data: true });
  }

  return (
    <IonHeader className={`cbo-header ${white ? "header--white" : ""}`}>
      <IonToolbar>
        <div className="header-buttons" slot="start">
          {backHref ? (
            <IonButton
              className="header-back"
              routerLink={backHref}
              routerDirection="back"
            >
              <IonIcon src={iconBack} />
            </IonButton>
          ) : (
            <div className="header-backwrap">
              {location.pathname !== routes.search && (
                <IonBackButton
                  className="header-back"
                  defaultHref={routes.search}
                  text=""
                  icon={iconBack}
                />
              )}
            </div>
          )}
          <ActionButton
            type="cart"
            target={
              location.pathname !== routes.order ? routes.order : undefined
            }
            badge={orderItems > 0 ? orderItems : undefined}
            active={location.pathname === routes.order}
          />
        </div>

        <div className="header-buttons" slot="end">
          <ActionButton type="logout" action={() => openLogoutModal()} />
        </div>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
