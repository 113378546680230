const series = {
  state: {
    entries: [],
    loadingEntries: false
  },

  reducer: (state, action) => {
    switch (action.type) {
      case "startSeriesEntries": {
        return { ...state, entries: [], loadingEntries: true };
      }
      case "endSeriesEntries": {
        return { ...state, loadingEntries: false };
      }
      case "setSeriesEntries": {
        return { ...state, entries: action.data, loadingEntries: false };
      }
      case "resetSeries": {
        return {
          ...state,
          entries: [],
          loadingEntries: false
        };
      }
      default:
      // Do nothing
    }
    return state;
  }
};

export default series;
