import { IonButton, IonIcon } from "@ionic/react";
import routes from "../config/routes";

import iconInvoice from "../assets/icons/icon-invoice.svg";
import "./InvoiceButton.scss";

interface ContainerProps {}

const InvoiceButton: React.FC<ContainerProps> = () => {
  return (
    <IonButton
      routerLink={routes.invoice}
      routerDirection="forward"
      className="cbo-invoicebtn"
      shape="round"
      slot="end"
    >
      <IonIcon slot="icon-only" src={iconInvoice}></IonIcon>
    </IonButton>
  );
};

export default InvoiceButton;
