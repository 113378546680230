const endpoints = {
  login: "/login_check",
  password: "/reset-password",
  passwordReset: "/reset-password/reset",
  brands: "/marque",
  models: "/modele",
  series: "/serie",
  seriesSearch: "/serie/search",
  registration: "/immatriculation",
  sheet: "/fiche-technique",
  invoice: "/facture",
  order: "/memo",
  orderUpdate: "/memo/update",
  orderSend: "/memo/send",
  welcome: "/welcome",
  licensePlates: "/license-plate"
};

export default endpoints;
