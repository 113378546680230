import "./Difficulty.scss";

interface ContainerProps {
  level: number | string;
}

const Difficulty: React.FC<ContainerProps> = ({ level }) => {
  const labels = [
    "Facile",
    "Moyen",
    "Difficile",
    "Impossible"
  ];

  let intLevel = parseInt(level.toString());

  if (intLevel < 1) intLevel = 1;
  if (intLevel > 4) intLevel = 4;

  return (
    <div className={`cbo-difficulty difficulty--${intLevel}`}>
      {labels[intLevel - 1]}
    </div>
  );
};

export default Difficulty;
