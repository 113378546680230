import React, { useEffect, useContext, useCallback } from "react";
import { IonLabel, IonInput, IonSelect, IonSelectOption } from "@ionic/react";
import { useForm, Controller } from "react-hook-form";
import { AppContext } from "../state";

interface ComponentProps {
  submit?: boolean;
}

const FormInvoiceCustomer: React.FC<ComponentProps> = ({ submit }) => {
  const { state, dispatch } = useContext(AppContext);

  interface CustomerProps {
    lastname?: string;
    firstname?: string;
    email?: string;
    address?: string;
    zipcode?: number;
    city?: string;
    statType?: string;
    statZipcode?: string;
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<CustomerProps>();

  const onSubmit = useCallback(
    (data: any) => {
      dispatch({ type: "setInvoiceCustomer", data: data });
      dispatch({ type: "setInvoiceMode", data: "preview" });
    },
    [dispatch]
  );

  useEffect(() => {
    if (submit) handleSubmit(onSubmit)();
  }, [submit, handleSubmit, onSubmit]);

  useEffect(() => {
    Object.keys(state.invoice.customer).forEach((key: any) => {
      const value = state.invoice.customer[key];
      setValue(key, value);
    });
  }, [state.invoice.customer, setValue]);

  return (
    <form className="edition-form cbo-form" autoComplete="off">
      <div className="ion-padding-bottom">
        <IonLabel position="stacked">Nom du client</IonLabel>
        <Controller
          name="lastname"
          control={control}
          rules={{
            required: true
          }}
          render={({ field: { value, onChange } }) => (
            <IonInput
              type="text"
              className={errors.lastname ? "cbo-errorfield" : ""}
              value={value}
              onIonChange={onChange}
            />
          )}
        />
      </div>
      <div className="ion-padding-bottom">
        <IonLabel position="stacked">Prénom du client</IonLabel>
        <Controller
          name="firstname"
          control={control}
          rules={{
            required: true
          }}
          render={({ field: { value, onChange } }) => (
            <IonInput
              type="text"
              className={errors.firstname ? "cbo-errorfield" : ""}
              value={value}
              onIonChange={onChange}
            />
          )}
        />
      </div>
      <div className="ion-padding-bottom">
        <IonLabel position="stacked">Email</IonLabel>
        <Controller
          name="email"
          control={control}
          rules={{
            required: true,
            pattern: /.+@.+/
          }}
          render={({ field: { value, onChange } }) => (
            <IonInput
              type="email"
              className={errors.email ? "cbo-errorfield" : ""}
              value={value}
              onIonChange={onChange}
            />
          )}
        />
      </div>
      <div className="ion-padding-bottom">
        <IonLabel position="stacked">Adresse postal (N° et voie)</IonLabel>
        <Controller
          name="address"
          control={control}
          rules={{
            required: true
          }}
          render={({ field: { value, onChange } }) => (
            <IonInput
              type="text"
              className={errors.address ? "cbo-errorfield" : ""}
              value={value}
              onIonChange={onChange}
            />
          )}
        />
      </div>
      <div className="ion-padding-bottom">
        <IonLabel position="stacked">Code postal</IonLabel>
        <Controller
          name="zipcode"
          control={control}
          rules={{
            required: true
          }}
          render={({ field: { value, onChange } }) => (
            <IonInput
              type="text"
              className={errors.zipcode ? "cbo-errorfield" : ""}
              value={value}
              onIonChange={onChange}
            />
          )}
        />
      </div>
      <div className="ion-padding-bottom">
        <IonLabel position="stacked">Ville</IonLabel>
        <Controller
          name="city"
          control={control}
          rules={{
            required: true
          }}
          render={({ field: { value, onChange } }) => (
            <IonInput
              type="text"
              className={errors.city ? "cbo-errorfield" : ""}
              value={value}
              onIonChange={onChange}
            />
          )}
        />
      </div>

      <div className="ion-padding-bottom">
        <IonLabel position="stacked">Type de client</IonLabel>

        <Controller
          name="statType"
          control={control}
          rules={{
            required: true
          }}
          render={({ field: { value, onChange } }) => (
            <IonSelect
              value={value}
              className={errors.statType ? "cbo-errorfield" : ""}
              onIonChange={onChange}
              interface="action-sheet"
              cancelText="Annuler"
            >
              <IonSelectOption value="pro">Pro</IonSelectOption>
              <IonSelectOption value="particulier">Particulier</IonSelectOption>
            </IonSelect>
          )}
        />
      </div>
      <div className="ion-padding-bottom">
        <IonLabel position="stacked">Code postal de l'intervention</IonLabel>
        <Controller
          name="statZipcode"
          control={control}
          rules={{
            required: true
          }}
          render={({ field: { value, onChange } }) => (
            <IonInput
              type="text"
              className={errors.statZipcode ? "cbo-errorfield" : ""}
              value={value}
              onIonChange={onChange}
            />
          )}
        />
      </div>
    </form>
  );
};

export default FormInvoiceCustomer;
