import { useContext } from "react";
import { AppContext } from "../state";
import { useApi } from "../config/api";

import ActionButton from "./ActionButton";
import "./Part.scss";

interface ContainerProps {
  name?: string;
  description?: string;
  order?: boolean;
  infos?: string;
}

const Footer: React.FC<ContainerProps> = ({
  name,
  description,
  order,
  infos
}) => {
  const { updateOrder } = useApi();
  const { state } = useContext(AppContext);

  function addItem() {
    const entries = [...state.order.entries];
    let found = false;
    entries.forEach((item: any, index: number) => {
      if (
        item.brand === state.sheet.brand.nom &&
        item.model === state.sheet.model.nom &&
        item.year === state.sheet.serie.annee &&
        item.name === name
      ) {
        entries[index].quantity++;
        found = true;
      }
    });

    if (!found) {
      entries.push({
        brand: state.sheet.brand.nom,
        model: state.sheet.model.nom,
        year: state.sheet.serie.annee,
        name: name,
        description: description,
        quantity: 1
      });
    }

    updateOrder( entries );
  }

  return (
    <div className="cbo-part">
      <div className="part-box">
        <div className="box-content">
          {name && <div className="content-name">{name}</div>}
          {description && (
            <div className="content-description">{description}</div>
          )}
        </div>

        {order && (
          <div className="box-action">
            <ActionButton type="cart" action={() => addItem()} />
          </div>
        )}
      </div>

      {infos && (
        <div className="part-infos cbo-cms">
          <p>{infos}</p>
        </div>
      )}
    </div>
  );
};

export default Footer;
