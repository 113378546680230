import React, { useState, useContext } from "react";
import { Redirect } from "react-router-dom";
import {
  IonContent,
  IonPage,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton
} from "@ionic/react";
import { useForm, Controller } from "react-hook-form";
import { useApi } from "../config/api";
import { AppContext } from "../state";
import routes from "../config/routes";
import InfoBubble from "../components/InfoBubble";

import "./Login.scss";

const Login: React.FC = (props: any) => {
  const { dispatch } = useContext(AppContext);
  const { setPassword } = useApi();
  const [changed, setChanged] = useState<boolean>(false);

  interface FormData {
    password: string;
    passwordConfirm: string;
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm<FormData>();

  const onSubmit = (data: any) => {
    setPassword(props.match.params.token, data.password)
      .then(() => {
        setChanged(true);
      })
      .catch(() => {
        dispatch({
          type: "setToaster",
          data: "Impossible de modifier le mot de passe."
        });
      });
  };

  if(changed) {
    return <Redirect to={routes.login} />
  }

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="cbo-login cbo-container">

          <div>
            <h1 className="ion-padding-bottom">
              <strong>Modifier votre mot de passe</strong>
            </h1>

            <InfoBubble icon="info">
              <p>
                Veuillez saisir votre nouveau mot de passe.<br />
                <strong>Minimum 8 caractères : au moins une majuscule, une minuscule, un chiffre et un caractère spécial.</strong>
              </p>
            </InfoBubble>
          </div>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="cbo-form fullwidth"
            autoComplete="off"
          >
            <IonList lines="none" className="ion-padding-bottom">
              <IonItem>
                <IonLabel position="stacked">Mot de passe</IonLabel>
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: true,
                    validate: (value) => {
                      const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
                      if(!re.test( value )){
                        return "Minimum 8 caractères : au moins une majuscule, une minuscule, un chiffre et un caractère spécial."
                      }
                    }
                  }}
                  render={({ field: { value, onChange } }) => (
                    <IonInput
                      type="password"
                      className={errors.password ? "cbo-errorfield" : ""}
                      value={value}
                      onIonChange={onChange}
                    />
                  )}
                />
                {errors.password && (
                  <div className="cbo-formerror">
                    {errors.password.message}
                  </div>
                )}
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">
                  Confirmer le mot de passe
                </IonLabel>
                <Controller
                  name="passwordConfirm"
                  control={control}
                  rules={{
                    required: true,
                    validate: (value) => {
                      if (watch("password") !== value) {
                        return "Les mots de passe ne correspondent pas";
                      }
                    }
                  }}
                  render={({ field: { value, onChange } }) => (
                    <IonInput
                      type="password"
                      className={errors.passwordConfirm ? "cbo-errorfield" : ""}
                      value={value}
                      onIonChange={onChange}
                    />
                  )}
                />
                {errors.passwordConfirm && (
                  <div className="cbo-formerror">
                    {errors.passwordConfirm.message}
                  </div>
                )}
              </IonItem>
            </IonList>

            <div className="ion-padding-top">
              <IonButton
                className="cbo-button"
                type="submit"
                expand="block"
                disabled={!!errors.password && !!errors.passwordConfirm}
              >
                Se connecter
              </IonButton>
            </div>
          </form>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
