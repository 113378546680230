import React, { createContext, useReducer } from "react";
import combineReducers from "react-combine-reducers";

import user from "./user.js";
import brands from "./brands.js";
import models from "./models.js";
import series from "./series.js";
import sheet from "./sheet.js";
import utils from "./utils.js";
import order from "./order.js";
import invoice from "./invoice.js";
import registration from "./registration.js";

let AppContext = createContext();

function AppContextProvider(props) {
  const [rootReducerCombined, initialStateCombined] = combineReducers({
    user: [user.reducer, user.state],
    brands: [brands.reducer, brands.state],
    models: [models.reducer, models.state],
    series: [series.reducer, series.state],
    sheet: [sheet.reducer, sheet.state],
    utils: [utils.reducer, utils.state],
    order: [order.reducer, order.state],
    invoice: [invoice.reducer, invoice.state],
    registration: [registration.reducer, registration.state]
  });

  let [state, dispatch] = useReducer(rootReducerCombined, initialStateCombined);
  let value = { state, dispatch };

  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
}

let AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };
