import { IonImg } from "@ionic/react";
import "./Picture.scss";

interface ContainerProps {
  src: string;
  legend?: string;
}

const Footer: React.FC<ContainerProps> = ({ src, legend }) => {
  return (
    <div className="cbo-picture">
      <div className="picture-wrapper">
        <IonImg src={src} alt={legend ? legend : ''} />
      </div>

      {legend && (
        <div className="picture-legend">
          {legend}
        </div>
      )}
    </div>
  );
};

export default Footer;
