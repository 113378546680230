import { IonIcon } from "@ionic/react";

import iconCheck from "../assets/icons/icon-check.svg";
import iconInfo from "../assets/icons/icon-info.svg";
import "./InfoBubble.scss";

interface ContainerProps {
  icon?: string;
  children: React.ReactNode;
}

const InfoBubble: React.FC<ContainerProps> = ({ icon, children }) => {
  return (
    <div className="cbo-infobubble">
      {icon && icon === 'info' && (
        <IonIcon src={iconInfo} />
      )}
      {icon && icon === 'check' && (
        <IonIcon src={iconCheck} />
      )}
      <div className="infobubble-text cbo-cms">
        {children}
      </div>
    </div>
  );
};

export default InfoBubble;
