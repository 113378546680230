const registration = {
  state: {
    entry: {},
    loading: false
  },

  reducer: (state, action) => {
    switch (action.type) {
      case "startRegistration": {
        return { ...state, entry: {}, loading: true };
      }
      case "endRegistration": {
        return { ...state, loading: false };
      }
      case "setRegistration": {
        return { ...state, entry: action.data, loading: false };
      }
      case "resetRegistration": {
        return {
          ...state,
          entry: {},
          loading: false
        };
      }
      default:
      // Do nothing
    }
    return state;
  }
};

export default registration;
